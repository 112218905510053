import * as faceapi from "face-api.js";
import React from "react";
import { FaCamera } from "react-icons/fa";

function FaceID({ setCaptureImage, capturedImage, islived, setIsLived }) {
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [captureVideo, setCaptureVideo] = React.useState(false);
  const [dominantExpression, setDominantExpression] = React.useState("");
  const [smileCount, setSmileCount] = React.useState(0); // Track number of smiles
  const [livelinessDetected, setLivelinessDetected] = React.useState(false); // Track liveliness
  const [headPositionValid, setHeadPositionValid] = React.useState(false); // Track head alignment
  const [isHeadStable, setIsHeadStable] = React.useState(false); // Track if head is stable

  const videoRef = React.useRef();
  const videoHeight = 480;
  const videoWidth = 640;
  const canvasRef = React.useRef();

  React.useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => setModelsLoaded(true));
    };
    loadModels();
  }, []);

  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const stopVideo = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
      setCaptureVideo(false);
    }
  };
  const captureImageAsBlob = async () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = videoWidth;
    canvas.height = videoHeight;

    context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

    canvas.toBlob((blob) => {
      if (blob) {
        console.log("Captured Image Blob:", blob);
        setCaptureImage(blob);
        stopVideo();
      }
    }, "image/png");
  };

  const handleVideoOnPlay = () => {
    setInterval(async () => {
      if (canvasRef.current && videoRef.current) {
        const detections = await faceapi
          .detectSingleFace(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions()
          )
          .withFaceLandmarks()
          .withFaceExpressions();
  
        if (detections) {
          const landmarks = detections.landmarks;
  
          // Analyze head position (pitch, yaw, roll)
          const nose = landmarks.getNose();
          const leftEye = landmarks.getLeftEye();
          const rightEye = landmarks.getRightEye();
  
          const dx = rightEye[0].x - leftEye[0].x;
          const dy = rightEye[0].y - leftEye[0].y;
          const roll = Math.atan2(dy, dx) * (180 / Math.PI); // Head tilt angle
  
          // Check if head is stable (head roll < 10 degrees)
          if (Math.abs(roll) < 10) {
            setHeadPositionValid(true);
            setIsHeadStable(true); // Head is stable
          } else {
            setHeadPositionValid(false);
            setIsHeadStable(false); // Head is not stable
          }
  
          const displaySize = { width: videoWidth, height: videoHeight };
  
          // Ensure canvasRef.current exists before using matchDimensions
          if (canvasRef.current) {
            faceapi.matchDimensions(canvasRef.current, displaySize);
  
            const resizedDetections = faceapi.resizeResults(detections, displaySize);
  
            const ctx = canvasRef.current.getContext("2d");
            ctx.clearRect(0, 0, videoWidth, videoHeight);
  
            faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
            faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
  
            // Find and display the dominant expression
            const expressions = detections.expressions;
            const maxExpression = Object.keys(expressions).reduce((a, b) =>
              expressions[a] > expressions[b] ? a : b
            );
            setDominantExpression(maxExpression);
  
            if (maxExpression === "happy") {
              setSmileCount((prevCount) => prevCount + 1);
            }
          }
        }
  
        if (smileCount >= 2 && !livelinessDetected) {
          setLivelinessDetected(true);
          setIsLived(true);
        }
      }
    }, 100);
  };
  

  React.useEffect(() => {
    // Capture the image when head is stable and liveliness is detected
    if (isHeadStable && smileCount >= 2 && !livelinessDetected) {
      setLivelinessDetected(true);
      captureImageAsBlob();
    }
  }, [isHeadStable, smileCount, livelinessDetected]);

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
    setDominantExpression("");
  };

  return (
    <div className="flex flex-col items-center p-4">
      <div className="text-center mb-4">
        <button
          onClick={startVideo}
          className="cursor-pointer flex flex-row gap-x-3 justify-center items-center bg-green-500 text-white py-2 px-2 text-xl rounded-lg"
        >
          <FaCamera /> Start Liveness Check
        </button>
      </div>
      {captureVideo ? (
        modelsLoaded ? (
          <div className="flex flex-col items-center">
            <div className="relative">
              <video
                ref={videoRef}
                height={videoHeight}
                width={videoWidth}
                onPlay={handleVideoOnPlay}
                className="h-auto w-auto rounded-t-[40%] rounded-b-[60%] border-4 border-green-500"
              />
              <canvas
                ref={canvasRef}
                className="absolute top-0 left-0"
                width={videoWidth}
                height={videoHeight}
                style={{display: "none"}}
              />
            </div>
            <div className="text-center font-bold mt-4">
              {headPositionValid ? (
                <div className="text-green-500 text-xl">
                  Head Position Aligned!
                </div>
              ) : (
                <div className="text-red-500 text-xl">
                  Please Align Your Head Properly
                </div>
              )}
              {livelinessDetected ? (
                <div className="text-green-500 text-xl">
                  Liveliness Detected!
                </div>
              ) : (
                <div className="text-red-500 text-xl">
                  Liveliness Not Detected
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )
      ) : null}
    </div>
  );
}

export default FaceID;
