import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Link, useParams,useNavigate } from "react-router-dom";
import axios from "../api";

export default function VerifyEmail() {
    const [processing, setProcessing] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const navigate = useNavigate();
    const toast = useRef(null);

    const showToast = (severity, summary, message) => {
        toast?.current?.show({
            severity,
            summary,
            detail: message,
            life: 5000,
        });
    };

    useEffect(() => {
        if (token) {
            setProcessing(true);
            // alert(processing)
            axios
                .get("https://ktapi.scan-verify.com/api/verify-email/"+token) // Replace with your endpoint
                .then((response) => {
                    setProcessing(false);
                    if (response.data.success == true) {
                        setIsVerify(true);
                        showToast("success", "Success", "Email verified successfully!");
                        localStorage.setItem('token', response?.data?.token);
                        localStorage.setItem('role', response?.data?.user?.role);
                        localStorage.setItem('userData', JSON.stringify(response?.data?.user));
                        navigate('/dashboard');
                    } else {
                        setIsExpired(true);
                        showToast("warn", "Expired", response.data.message);
                    }
                })
                .catch((error) => {
                    setProcessing(false);
                    setIsExpired(true);
                    showToast("error", "Error", error.response.data.message);
                });
        }else{
            // alert('Refresh your browser')
        }
    }, [token]);

    return (
        <div className="flex items-center justify-center lg:max-w-screen-md p-12 w-full sm:w-4/5 md:w-1/2 mx-auto">
            <div className="bg-white p-6 rounded-md shadow-md w-full">
                <h1 className="font-bold text-3xl text-green-500 text-center mb-4">
                    Email Verification
                </h1>
                <Toast ref={toast} />

                {processing && (
                    <div className="flex items-center justify-center my-4">
                        <svg
                            className="animate-spin h-6 w-6 text-green-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            aria-label="Loading"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        <p className="ml-3 text-gray-600 font-medium">Verifying...</p>
                    </div>
                )}

                {!processing && isVerify && (
                    <p className="text-green-600 font-medium text-center">
                        Your email has been verified successfully! 
                    </p>
                )}

                {!processing && isExpired && (
                    <p className="text-red-600 font-medium text-center">
                        This verification link has expired. Please request a new one.
                    </p>
                )}

                <div className="flex flex-row gap-x-6 items-center justify-center mt-6">
                    <button
                        onClick={() => {
                            console.log("Resend clicked");
                            showToast("info", "Resend", "Verification email resent.");
                        }}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        <i className="pi pi-refresh mr-2"></i> Resend
                    </button>
                    <Link to="/login">
                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            Login
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
