// src/Service/ProductService.jsx

import axios from '../api';

const ProductService = {
  getProducts: async () => {
    try {
      const response = await axios.get('https://ktapi.scan-verify.com/api/sales', {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Customer and Products:', response.data.products);

      return response.data.products

    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  },
};

export default ProductService;
