import axios from 'axios';

// Add a response interceptor to the Axios instance
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Redirect to login page if unauthorized
      if (error.response.status === 401) {
        // localStorage.clear();
        // if (typeof window !== 'undefined') {
        //   localStorage.setItem('message_403', error.response.data.message);
        //   window.location.href = '/login'; // Redirects to login page
        // }
      } else if (error.response.status === 403 || error.response.status === 401) {
        localStorage.setItem('message_403', error.response.data.message);
        window.location.href = '/unauthorized'; // Redirects to unauthorized page
      }
    }
    return Promise.reject(error);
  }
);

// Configure the default Axios instance
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Function to get user data from the API and update localStorage
const fetchUserData = async () => {
  try {
    const response = await axios.get('https://ktapi.scan-verify.com/api/users/auth'); // Call to the backend route
    if (response.data) {
      // Update localStorage with the user data (including role)
      localStorage.setItem('userData', JSON.stringify(response.data));
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

// Call the fetchUserData function on app load or component mount
fetchUserData();

// Create an Axios instance or use the default one
const api = axios.create();

export default axios;
export { api };
