// src/pages/RegisterPage.js
import React, { useState,useEffect, useRef } from 'react';
import { Button, InputText, Password, Card } from 'primereact';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputField from '../../components/InputField';
import { ProgressSpinner } from 'primereact/progressspinner'; 
import { Toast } from 'primereact/toast';
import LoginBanner from '../../components/LoginBanner';
import { Dropdown } from 'primereact/dropdown';
import { FaExclamationCircle } from 'react-icons/fa';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAgent, setIsAgent] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [agentFound, setAgentFound] = useState(false);
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    fullName: '',
    contact: '',
    email:'',
    lga:'',
    ward:'',
    password:'',
    app_type:'',
    bizi_agent_id:'',
    psn: '',
});


const [errorMessages, setErrorMessages] = useState([]);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

const lgas = [
  { id: 1, lga: "Auyo" },
  { id: 2, lga: "Babura" },
  { id: 3, lga: "Biriniwa" },
  { id: 4, lga: "Birnin Kudu" },
  { id: 5, lga: "Buji" },
  { id: 6, lga: "Dutse" },
  { id: 7, lga: "Gagarawa" },
  { id: 8, lga: "Garki" },
  { id: 9, lga: "Gumel" },
  { id: 10, lga: "Guri" },
  { id: 11, lga: "Gwaram" },
  { id: 12, lga: "Gwiwa" },
  { id: 13, lga: "Hadejia" },
  { id: 14, lga: "Jahun" },
  { id: 15, lga: "Kafin Hausa" },
  { id: 16, lga: "Kazaure" },
  { id: 17, lga: "Kiri Kasama" },
  { id: 18, lga: "Kiyawa" },
  { id: 19, lga: "Kaugama" },
  { id: 20, lga: "Maigatari" },
  { id: 21, lga: "Malam Madori" },
  { id: 22, lga: "Miga" },
  { id: 23, lga: "Ringim" },
  { id: 24, lga: "Roni" },
  { id: 25, lga: "Sule Tankarkar" },
  { id: 26, lga: "Taura" },
  { id: 27, lga: "Yankwashi" }
]

const wardsData = [
  { id: 'AUYO', ward: 'AUYO' },
  { id: 'AUYAKAYI', ward: 'AUYAKAYI' },
  { id: 'AYAMA', ward: 'AYAMA' },
  { id: 'AYAN', ward: 'AYAN' },
  { id: 'GAMAFOI', ward: 'GAMAFOI' },
  { id: 'GAMSARKA', ward: 'GAMSARKA' },
  { id: 'GATAFA', ward: 'GATAFA' },
  { id: 'KAFUR', ward: 'KAFUR' },
  { id: 'TSIDIR', ward: 'TSIDIR' },
  { id: 'UNIK', ward: 'UNIK' },
  { id: 'BABURA', ward: 'BABURA' },
  { id: 'BATALI', ward: 'BATALI' },
  { id: 'DORAWA', ward: 'DORAWA' },
  { id: 'GARU', ward: 'GARU' },
  { id: 'GASAKOLI', ward: 'GASAKOLI' },
  { id: 'INSHARUWA', ward: 'INSHARUWA' },
  { id: 'JIGAWA', ward: 'JIGAWA' },
  { id: 'KANYA', ward: 'KANYA' },
  { id: 'KUZUNZUMI', ward: 'KUZUNZUMI' },
  { id: 'KYAMBO', ward: 'KYAMBO' },
  { id: 'TAKWASA', ward: 'TAKWASA' },
  { id: 'BIRNIN KUDU', ward: 'BIRNIN KUDU' },
  { id: 'KANGIRE', ward: 'KANGIRE' },
  { id: 'KANTOGA', ward: 'KANTOGA' },
  { id: 'KIYAKO', ward: 'KIYAKO' },
  { id: 'KWANGWARA', ward: 'KWANGWARA' },
  { id: 'LAFIYA', ward: 'LAFIYA' },
  { id: 'SUNDIMINA', ward: 'SUNDIMINA' },
  { id: 'SURKO', ward: 'SURKO' },
  { id: 'UNGUWARYA', ward: 'UNGUWARYA' },
  { id: 'WURNO', ward: 'WURNO' },
  { id: 'YALWAN DAMAI', ward: 'YALWAN DAMAI' },
  { id: 'BATU', ward: 'BATU' },
  { id: 'BIRNIWA', ward: 'BIRNIWA' },
  { id: 'DANGWALERI', ward: 'DANGWALERI' },
  { id: 'DIGINSA', ward: 'DIGINSA' },
  { id: 'FAGI', ward: 'FAGI' },
  { id: 'KACHALLARI', ward: 'KACHALLARI' },
  { id: 'KARANKA', ward: 'KARANKA' },
  { id: 'KAZURA', ward: 'KAZURA' },
  { id: 'MACHINAMARI', ward: 'MACHINAMARI' },
  { id: 'MATAMU', ward: 'MATAMU' },
  { id: 'NGUWA', ward: 'NGUWA' },
  { id: 'AHOTO', ward: 'AHOTO' },
  { id: 'BUJI', ward: 'BUJI' },
  { id: 'CHURBUN', ward: 'CHURBUN' },
  { id: 'GANTSA', ward: 'GANTSA' },
  { id: 'FALAGERI', ward: 'FALAGERI' },
  { id: 'KAWAYA', ward: 'KAWAYA' },
  { id: 'KUKUMA', ward: 'KUKUMA' },
  { id: 'K/LELEN KUDU', ward: 'K/LELEN KUDU' },
  { id: 'MADABE', ward: 'MADABE' },
  { id: 'Y/TUKUR', ward: 'Y/TUKUR' },
  { id: 'ABAYA', ward: 'ABAYA' },
  { id: 'CHAMO', ward: 'CHAMO' },
  { id: 'DUNDUBUS', ward: 'DUNDUBUS' },
  { id: 'DURU', ward: 'DURU' },
  { id: 'JIGAWAR TSADA', ward: 'JIGAWAR TSADA' },
  { id: 'KACHI', ward: 'KACHI' },
  { id: 'KARNAYA', ward: 'KARNAYA' },
  { id: 'KUDAI', ward: 'KUDAI' },
  { id: 'LIMAWA', ward: 'LIMAWA' },
  { id: 'MADOBI', ward: 'MADOBI' },
  { id: 'SAKWAYA', ward: 'SAKWAYA' },
  { id: 'BUDURU', ward: 'BUDURU' },
  { id: 'DOKO', ward: 'DOKO' },
  { id: 'GARKI', ward: 'GARKI' },
  { id: 'GWARZON GARKI', ward: 'GWARZON GARKI' },
  { id: 'JIRIMA', ward: 'JIRIMA' },
  { id: 'KANYA', ward: 'KANYA' },
  { id: 'KARGO', ward: 'KARGO' },
  { id: 'KORE', ward: 'KORE' },
  { id: 'MUKU', ward: 'MUKU' },
  { id: 'RAFIN MARKE', ward: 'RAFIN MARKE' },
  { id: 'SIYORI', ward: 'SIYORI' },
  { id: 'GAGARAWA GARI', ward: 'GAGARAWA GARI' },
  { id: 'GAGARAWA TASHA', ward: 'GAGARAWA TASHA' },
  { id: 'GARIN CHIROMA', ward: 'GARIN CHIROMA' },
  { id: 'KORE BALATU', ward: 'KORE BALATU' },
  { id: 'MADAKA', ward: 'MADAKA' },
  { id: 'MAIADUWA', ward: 'MAIADUWA' },
  { id: 'MAIKILILI', ward: 'MAIKILILI' },
  { id: 'MEDU', ward: 'MEDU' },
  { id: 'YALAWA', ward: 'YALAWA' },
  { id: 'ZARADA', ward: 'ZARADA' },
  { id: 'BAIKARYA', ward: 'BAIKARYA' },
  { id: 'DANAMA', ward: 'DANAMA' },
  { id: 'DANTANOMA', ward: 'DANTANOMA' },
  { id: 'GALAGAMMA', ward: 'GALAGAMMA' },
  { id: 'GARIN GAMBO', ward: 'GARIN GAMBO' },
  { id: 'GARIN ALHAJI BARKA', ward: 'GARIN ALHAJI BARKA' },
  { id: 'GUSAU', ward: 'GUSAU' },
  { id: 'HAMMADO', ward: 'HAMMADO' },
  { id: 'KOFAR AREWA', ward: 'KOFAR AREWA' },
  { id: 'KOFAR YAMMA', ward: 'KOFAR YAMMA' },
  { id: 'ZANGO', ward: 'ZANGO' },
  { id: 'ABUNABO', ward: 'ABUNABO' },
  { id: 'ADIYANI', ward: 'ADIYANI' },
  { id: 'DAWA', ward: 'DAWA' },
  { id: 'GARBAGAL', ward: 'GARBAGAL' },
  { id: 'GURI', ward: 'GURI' },
  { id: 'KADIRA', ward: 'KADIRA' },
  { id: 'LAFIYA', ward: 'LAFIYA' },
  { id: 'MARGADU', ward: 'MARGADU' },
  { id: 'MATARA BABA', ward: 'MATARA BABA' },
  { id: 'MUSARI', ward: 'MUSARI' },
  { id: 'BASIRKA', ward: 'BASIRKA' },
  { id: 'DINGAYA', ward: 'DINGAYA' },
  { id: 'FAGAM', ward: 'FAGAM' },
  { id: 'FARIN DUTSE', ward: 'FARIN DUTSE' },
  { id: 'GWARAM TSOHUWA', ward: 'GWARAM TSOHUWA' },
  { id: 'BUNTUSU', ward: 'BUNTUSU' },
  { id: 'DABI', ward: 'DABI' },
  { id: 'DARINA', ward: 'DARINA' },
  { id: 'F/YAMMA', ward: 'F/YAMMA' },
  { id: 'GUNTAI', ward: 'GUNTAI' },
  { id: 'GWIWA', ward: 'GWIWA' },
  { id: 'KORAYEL', ward: 'KORAYEL' },
  { id: 'RORAU', ward: 'RORAU' },
  { id: 'SHAFE', ward: 'SHAFE' },
  { id: 'YOLA', ward: 'YOLA' },
  { id: 'ZAUMAR SAINAWA', ward: 'ZAUMAR SAINAWA' },
  // HADEJIA Wards
{ id: 'ATAFI', ward: 'ATAFI' },
{ id: 'DUBANTU', ward: 'DUBANTU' },
{ id: 'GAGULMARI', ward: 'GAGULMARI' },
{ id: 'KASUWAR KUDA', ward: 'KASUWAR KUDA' },
{ id: 'KASUWAR KOFA', ward: 'KASUWAR KOFA' },
{ id: 'MAJEMA', ward: 'MAJEMA' },
{ id: 'MATSARO', ward: 'MATSARO' },
{ id: 'RUMFA', ward: 'RUMFA' },
{ id: 'SABON GARU', ward: 'SABON GARU' },
{ id: 'YANKOLI', ward: 'YANKOLI' },
{ id: 'YAYARI', ward: 'YAYARI' },

// JAHUN Wards
{ id: 'AUJARA', ward: 'AUJARA' },
{ id: 'GANGAWA', ward: 'GANGAWA' },
{ id: 'GAUZA TAZARA', ward: 'GAUZA TAZARA' },
{ id: 'GUNKA', ward: 'GUNKA' },
{ id: 'HARBO SABUWA', ward: 'HARBO SABUWA' },
{ id: 'HARBO TSOHUWA', ward: 'HARBO TSOHUWA' },
{ id: 'IDANDUNA', ward: 'IDANDUNA' },
{ id: 'JABARNA', ward: 'JABARNA' },
{ id: 'JAHUN', ward: 'JAHUN' },
{ id: 'KALE', ward: 'KALE' },
{ id: 'KANWA', ward: 'KANWA' },

// KAFIN HAUSA Wards
{ id: 'BALANGU', ward: 'BALANGU' },
{ id: 'DUMADUMIN TOKA', ward: 'DUMADUMIN TOKA' },
{ id: 'GAFAYA', ward: 'GAFAYA' },
{ id: 'JABO', ward: 'JABO' },
{ id: 'KAFIN HAUSA', ward: 'KAFIN HAUSA' },
{ id: 'KAZALEWA', ward: 'KAZALEWA' },
{ id: 'MAJAWA', ward: 'MAJAWA' },
{ id: 'MEZAN', ward: 'MEZAN' },
{ id: 'RUBA', ward: 'RUBA' },
{ id: 'SARAWA', ward: 'SARAWA' },
{ id: 'ZAGO', ward: 'ZAGO' },

// KAUGAMA Wards
{ id: 'ARBUS', ward: 'ARBUS' },
{ id: 'ASKANDU', ward: 'ASKANDU' },
{ id: 'DABUWARAN', ward: 'DABUWARAN' },
{ id: 'DAKAIYAWA', ward: 'DAKAIYAWA' },
{ id: 'HADIN', ward: 'HADIN' },
{ id: 'JA\'E', ward: 'JA\'E' },
{ id: 'JARKASA', ward: 'JARKASA' },
{ id: 'KAUGAMA', ward: 'KAUGAMA' },
{ id: 'MARKE', ward: 'MARKE' },
{ id: 'UNGUWAR JIBRIN', ward: 'UNGUWAR JIBRIN' },
{ id: 'YALO', ward: 'YALO' },

// KAZAURE Wards
{ id: 'BA\'AUZINI', ward: 'BA\'AUZINI' },
{ id: 'DABA', ward: 'DABA' },
{ id: 'DABAZA', ward: 'DABAZA' },
{ id: 'DANDI', ward: 'DANDI' },
{ id: 'GADA', ward: 'GADA' },
{ id: 'KANTI', ward: 'KANTI' },
{ id: 'MARADAWA', ward: 'MARADAWA' },
{ id: 'SABARU', ward: 'SABARU' },
{ id: 'UNGUWAR AREWA', ward: 'UNGUWAR AREWA' },
{ id: 'UNGUWAR GABAS', ward: 'UNGUWAR GABAS' },
{ id: 'UNGUWAR YAMMA', ward: 'UNGUWAR YAMMA' },

// KIRIKA SAMMA Wards
{ id: 'BATURIYA', ward: 'BATURIYA' },
{ id: 'BULUNCHAI', ward: 'BULUNCHAI' },
{ id: 'DOLERI', ward: 'DOLERI' },
{ id: 'FANDUM', ward: 'FANDUM' },
{ id: 'GAYIN', ward: 'GAYIN' },
{ id: 'KIRIKA SAMMA', ward: 'KIRIKA SAMMA' },
{ id: 'MADACHI', ward: 'MADACHI' },
{ id: 'MARMA', ward: 'MARMA' },
{ id: 'TSHEGUWA', ward: 'TSHEGUWA' },
{ id: 'TARABU', ward: 'TARABU' },

// KIYAWA Wards
{ id: 'ABALAGO', ward: 'ABALAGO' },
{ id: 'ANDAZA', ward: 'ANDAZA' },
{ id: 'FAKI', ward: 'FAKI' },
{ id: 'GARKO', ward: 'GARKO' },
{ id: 'GURUDUBA', ward: 'GURUDUBA' },
{ id: 'KATANGA', ward: 'KATANGA' },
{ id: 'KATUKA', ward: 'KATUKA' },
{ id: 'KIYAWA', ward: 'KIYAWA' },
{ id: 'KWANDA', ward: 'KWANDA' },
{ id: 'MAJE', ward: 'MAJE' },
{ id: 'TSURMA', ward: 'TSURMA' },

// MAIGATARI Wards
{ id: 'BALARABE', ward: 'BALARABE' },
{ id: 'DANKUMBO', ward: 'DANKUMBO' },
{ id: 'FULATA', ward: 'FULATA' },
{ id: 'GALADI', ward: 'GALADI' },
{ id: 'JAJERI', ward: 'JAJERI' },
{ id: 'KUKAYASKU', ward: 'KUKAYASKU' },
{ id: 'MADANA', ward: 'MADANA' },
{ id: 'MAIGATARI AREWA', ward: 'MAIGATARI AREWA' },
{ id: 'MAIGATARI KUDU', ward: 'MAIGATARI KUDU' },
{ id: 'MATOYA', ward: 'MATOYA' },
{ id: 'TURBUS', ward: 'TURBUS' },

// MALAM MADORI Wards
{ id: 'ARKI', ward: 'ARKI' },
{ id: 'DUNARI', ward: 'DUNARI' },
{ id: 'FATEKA AKURYA', ward: 'FATEKA AKURYA' },
{ id: 'GARIN GABAS', ward: 'GARIN GABAS' },
{ id: 'MAIRA KUMI-BARA MUSA', ward: 'MAIRA KUMI-BARA MUSA' },
{ id: 'MAKA DDARI', ward: 'MAKA DDARI' },
{ id: 'MALAM MADORI', ward: 'MALAM MADORI' },
{ id: 'SHAIYA', ward: 'SHAIYA' },
{ id: 'TAGWARO', ward: 'TAGWARO' },
{ id: 'TASHENA', ward: 'TASHENA' },
{ id: 'TONIKUTARA', ward: 'TONIKUTARA' },

// MIGA Wards
{ id: 'DANGYATIN', ward: 'DANGYATIN' },
{ id: 'GARBO', ward: 'GARBO' },
{ id: 'HANTSU', ward: 'HANTSU' },
{ id: 'KOYA', ward: 'KOYA' },
{ id: 'MIGA', ward: 'MIGA' },
{ id: 'SABON GARI TAKANEBU', ward: 'SABON GARI TAKANEBU' },
{ id: 'SANSANI', ward: 'SANSANI' },
{ id: 'TSAKUWAWA', ward: 'TSAKUWAWA' },
{ id: 'YANDUNA', ward: 'YANDUNA' },
{ id: 'ZAREKU', ward: 'ZAREKU' },

// RINGIM Wards
{ id: 'CHAI-CHAI', ward: 'CHAI-CHAI' },
{ id: 'DABI', ward: 'DABI' },
{ id: 'KAFIN BABUSHE', ward: 'KAFIN BABUSHE' },
{ id: 'KARSHI', ward: 'KARSHI' },
{ id: 'KYARAMA', ward: 'KYARAMA' },
{ id: 'RINGIM', ward: 'RINGIM' },
{ id: 'SANKARA', ward: 'SANKARA' },
{ id: 'SINTILMAWA', ward: 'SINTILMAWA' },
{ id: 'TOFA', ward: 'TOFA' },
{ id: 'YANDUTSE', ward: 'YANDUTSE' },

// RONI Wards
{ id: 'AMARYAWA', ward: 'AMARYAWA' },
{ id: 'BARAGUMI', ward: 'BARAGUMI' },
{ id: 'DANSURE', ward: 'DANSURE' },
{ id: 'FARA', ward: 'FARA' },
{ id: 'GORA', ward: 'GORA' },
{ id: 'KWAITA', ward: 'KWAITA' },
{ id: 'RONI', ward: 'RONI' },
{ id: 'SANKAU', ward: 'SANKAU' },
{ id: 'TUNAS', ward: 'TUNAS' },
{ id: 'YANZAKI', ward: 'YANZAKI' },
{ id: 'ZUGAI', ward: 'ZUGAI' },

// SULE-TANKARKAR Wards
{ id: 'ALBASU', ward: 'ALBASU' },
{ id: 'AMANGA', ward: 'AMANGA' },
{ id: 'DANGWANKI', ward: 'DANGWANKI' },
{ id: 'DANLADI', ward: 'DANLADI' },
{ id: 'DANZOMO', ward: 'DANZOMO' },
{ id: 'JEKE', ward: 'JEKE' },
{ id: 'SHABARU', ward: 'SHABARU' },
{ id: 'SULE-TANKARKAR', ward: 'SULE-TANKARKAR' },
{ id: 'TAKATSABA', ward: 'TAKATSABA' },
{ id: 'YANDAMO', ward: 'YANDAMO' },

// TAURA Wards
{ id: 'AJAURA', ward: 'AJAURA' },
{ id: 'CHAKWAIKWAIWA', ward: 'CHAKWAIKWAIWA' },
{ id: 'CHUKUTO', ward: 'CHUKUTO' },
{ id: 'GUJUNGU', ward: 'GUJUNGU' },
{ id: 'KIRI', ward: 'KIRI' },
{ id: 'KWALAM', ward: 'KWALAM' },
{ id: 'MAJE', ward: 'MAJE' },
{ id: 'MAJIYA', ward: 'MAJIYA' },
{ id: 'S/GARIN YAYA', ward: 'S/GARIN YAYA' },
{ id: 'TAURA', ward: 'TAURA' },

// YANKWASHI Wards
{ id: 'ACHILAFIYA', ward: 'ACHILAFIYA' },
{ id: 'BELAS', ward: 'BELAS' },
{ id: 'DAWAN-GAWO', ward: 'DAWAN-GAWO' },
{ id: 'GWARTA', ward: 'GWARTA' },
{ id: 'GURJIYA', ward: 'GURJIYA' },
{ id: 'KARKARNA', ward: 'KARKARNA' },
{ id: 'KUDA', ward: 'KUDA' },
{ id: 'RINGIM', ward: 'RINGIM' },
{ id: 'YANKWASHI', ward: 'YANKWASHI' },
{ id: 'ZUNGUMBA', ward: 'ZUNGUMBA' }

];

  const load = () => {
      setLoading(true);

      setTimeout(() => {
          setLoading(false);
      }, 2000);
  };

  const toast = useRef(null);

    const showSuccess = (severity,summary,message) => {
        toast.current.show({severity:severity, summary: summary, detail:message, life: 10000});
    }



  const handleLogin = async (e) => {
    e.preventDefault();

    setProcessing(true);
    // Ensure email and password are filled
    if (email && password) {
        try {
            // First, call the CSRF cookie endpoint
            await axios.get('https://ktapi.scan-verify.com/sanctum/csrf-cookie');

            // After setting the CSRF cookie, make the POST request to your login endpoint
            const response = await axios.post('https://ktapi.scan-verify.com/api/login', {
                email: email,
                password: password,
            });

            setProcessing(false);
            if (response.data.success) {
                // Get the token from the response
                const token = response?.data?.token;
                const role = response?.data?.role;
                const user = response?.data?.user;

                // Store the token in local storage
                localStorage.setItem('token', token);
                localStorage.setItem('role', role);
                // Store the user data in local storage after stringifying it
                localStorage.setItem('userData', JSON.stringify(user));
                localStorage.setItem('balance', user?.agent?.balance);

                showSuccess('success', 'Login', 'Login successfully');

                setTimeout(() => {
                    navigate('/dashboard');
                }, 3000);

            } else if(response.status == 403) {
                showSuccess('error', 'Login', response?.data?.message);

            }else{
              showSuccess('error', 'Login', response?.data?.error);
            }

            // Redirect to the dashboard

        } catch (error) {

            setProcessing(false);
            
            // Handle errors here (e.g., show an error message)
            showSuccess('error', 'Login', error.response.data.message);
            console.error('Login failed:', error);
        }
    } else {
        showSuccess('error', 'Login', 'Please enter both email and password.');
    }
};



const appType = [
  { id: 'agent', name: 'Agent' },
  { id: 'beneficiary', name: 'Beneficiary' },
]

// const handleSubmit = async (e) => {
//   e.preventDefault();

//   const form = new FormData();

//   // Append each field from formData to the FormData object
//   form.append('name', formData.fullName);
//   form.append('contact', formData.contact);
//   form.append('email', formData.email);
//   form.append('ward', formData.ward.ward);
//   form.append('lga', formData.lga.lga);
//   form.append('password', formData.password);

//   setProcessing(true);
//   try {
//       const response = await axios.post('https://ktapi.scan-verify.com/api/users/create-agent',form, {
//           headers: {
//              'Content-Type': 'multipart/form-data'
//           }
//       }); 
      
//       // console.log('Product added:', response.data);
//       setProcessing(false);
//       if(response.data.success == true){
//           fetch()
//           setFormData([]);
//           showSuccess('success',"Users",response.data.message)
//       }else if(response.data.errors){
//           setErrorMessages(response.data.errors);
//       }else{
//           showSuccess('error',"Users",response.data.message)
//       }
//   } catch (error) {
//       setProcessing(false);
//       setErrorMessages(error.request.response);
//       showSuccess('error',"Shop",error.request.response)
//       // console.error('Error adding product:', error.request.response);
//       console.log(errorMessages)
//   }
// };
const handleSubmit = async (e) => {
  e.preventDefault();

  const form = new FormData();
  // Append each field from formData to the FormData object
  form.append('name', formData.fullName);
  // form.append('bizi_agent_id', formData?.bizi_agent_id ?? '');
  form.append('contact', formData?.contact);
  form.append('psn', formData?.psn);
  form.append('email', formData?.email);
  form.append('ward', formData?.ward?.ward ?? '');
  form.append('lga', formData?.lga?.lga ?? '');
  form.append('password', formData?.password);

  setProcessing(true); // Set the loading state
  try {
    const response = await axios.post(
      'https://ktapi.scan-verify.com/api/users/create-agent',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    setProcessing(false);

    if (response.data.success) {
      setFormData([]); // Reset form data to an initial empty state
      showSuccess('success', 'Users', response?.data?.message);
    } else if (response.data.errors) {
      setErrorMessages(response.data.errors); // Update error messages
    } else {
      showSuccess('error', 'Users', response.data.message);
    }
  } catch (error) {
    setProcessing(false);
    if (error.response && error.response.data) {

      setErrorMessages(error.response.data.errors); // Parse server validation errors
      showSuccess('error', 'Users', error.response.data.message);
      console.log(error.response.data)

    } else {
      showSuccess('error', 'Users', 'Something went wrong!');
    }
  }
};


const verifyAgent = async () => {
  setProcessing(true); // Start processing
  
  try {
      // Make the GET request to verify the sale
      const response = await fetch(`https://app.jigawapalliative.com.ng/api/bizi-agent?bizi_code=${formData.bizi_agent_id}`, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.REACT_APP_AGENT_API_KEY}`
          }
      });
   
      const data = await response.json();
      // Handle the successful response here
      if (data.success == true) {

          console.log('Error', data.message)

                // console.log("response"+data?.agent?.agent_id,"provided"+formData?.bizi_agent_id)
          if(data?.agent?.agent_id == formData?.bizi_agent_id) {
              setAgentFound(true)
              showSuccess('success', 'Agent Verify', "Agent Code is valid");
          }else{
              showSuccess('error', 'Agent Verify', "Agent ID does not match");
              setAgentFound(false)
          }
             

      } else {
          setNotFound(true)
          showSuccess('error', 'Agent Verify', data.message);
      }

  } catch (error) {
      // Handle errors and display a relevant message
      console.error('Error Agent Verify:', error);
      showSuccess('error', 'Agent Verify', error.message);
  } finally {
      // Ensure processing is stopped regardless of the outcome
      setProcessing(false);
  }
};




const verifyPSN = async () => {
  setProcessing(true); // Start processing

  try {
      // Make the GET request to verify the sale
      const response = await axios.get(`https://ktapi.scan-verify.com/api/customer/verify/${formData.psn}`, {
          headers: {
              'Content-Type': 'application/json'
          }
      });

      // Handle the successful response here
      if (response.data.success == true) {

          console.log('Error', response.data.message)
          showSuccess('success', 'PSN Verify', response?.data?.message);
          setData(response.data.psn)
              setFormData((prevState) => ({
                  ...prevState,
                  full_name: response.data?.psn?.staff_name,
              }));

      } else {
          setNotFound(true)
          // showSuccess('error', 'PSN Verify', "PSN Not found");
          showSuccess('error', 'PSN Verify', response.data.message);
      }

  } catch (error) {
      // Handle errors and display a relevant message
      console.error('Error PSN Verify:', error);
      showSuccess('error', 'PSN Verify', error.response);
  } finally {
      // Ensure processing is stopped regardless of the outcome
      setProcessing(false);
  }
};

useEffect(() => {
  if (formData?.bizi_agent_id?.length == 10 || formData?.bizi_agent_id?.length == 11 ) {
      verifyAgent();
  }
}, [formData.bizi_agent_id]);


useEffect(() => {
  if (formData?.psn?.length == 6) {
     verifyPSN()
  }
  
}, [formData.psn]);

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
}

useEffect(() => {


    const token = localStorage.getItem('token');
    const message403 = localStorage.getItem('message_403');

    // if (token) {

    //   window.location.href="/dashboard"

    // }else{
    //     navigate('/login');
    // }

    if(message403 !== null){
      showSuccess('error', 'Login', message403);
    }

  }, []);

  return (
   <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} h-screen bg-white items-center justify-center`}>
    {isMobile && (
      <div className='w-1/2 bg-green-300'>
        <LoginBanner />
    </div>
    )}

    <div className={` ${isMobile} md:w-1/2 sm:w-full w-full items-center justify-center bg-white mb-5 mt-[35%]`}>
    <Toast ref={toast} />
      <div className="md:w-1/2 sm:w-full max-w-screen-sm p-6 items-center mx-auto mt-8">
      <img src="https://www.jigawapalliative.com.ng/wp-content/uploads/2024/07/e-500-x-199-px-1-e1721594916831.png" className='mx-auto  w-30 h-20' />
       <h1 className='font-bold  mx-auto  mb-4 text-gray-600 place-self-center text-2xl'>Register</h1>
        <form onSubmit={handleLogin}>
          <div className="">

          <label htmlFor="app_type" className=" mt-2 block font-bold text-base font-medium text-[#41bd18]">
                        App Type <b className='text-red-500'>*</b>
          </label>
          <div className="mt-2 flex justify-content-center w-full  mb-2">
                  <Dropdown filter={true} placeholder='Select App Type' name='app_type'  value={formData?.app_type} onChange={handleInputChange} options={appType} optionValue='id' optionLabel="name" className="w-full text-green-500 border border-green-500 px-4 py-2" />
          </div>

          {/* {formData?.app_type === 'agent' && (
            <div className="mb-4">
              <p className="text-red-500 font-bold">
                Please note that you are registering as an agent. kindly visit to register as , please visit <a href="https://app.jigawapalliative.com.ng/register?ref=main" target="_blank" className="text-blue-500 underline">Agent Portal</a> to register as an agent. to recieive your Bizi Agent ID
              </p>
            </div>
          )} */}

            {/* {formData?.app_type === 'agent'? (
              <div className="mb-2">
              <label htmlFor="bizi_agent_id" className='font-bold mb-0 text-[#41bd18]'>Bizi Agent ID <b className='text-red-500'>*</b></label>
              <input
                    id="bizi_agent_id"
                    name='bizi_agent_id'
                    placeholder={"Enter your bizi agent id"}
                    value={formData?.bizi_agent_id}
                    onChange={handleInputChange}
                    required
                    className="rounded-md  py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-bold outline-none focus:border-[#012206] focus:shadow-md"
                />
                {errorMessages?.bizi_agent_id && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.bizi_agent_id}</span>}
            </div>
              ):( */}

          {formData?.app_type === 'beneficiary' && (
                <div className="mb-2">
                <label htmlFor="bizi_agent_id" className='font-bold mb-0 text-[#41bd18]'>Personal Service Number (PSN/JSSB,JLG/JSG Number) <b className='text-red-500'>*</b></label>
                <input
                      id="psn"
                      name='psn'
                      placeholder={"Enter your personal service number"}
                      value={formData?.psn}
                      onChange={handleInputChange}
                      required
                      className="rounded-md  py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-bold outline-none focus:border-[#012206] focus:shadow-md"
                  />
                  {errorMessages?.psn && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.psn}</span>}
              </div>
            )}
           

          <div className="mb-2">
              <label htmlFor="full_name" className='font-bold mb-0 text-[#41bd18]'>Full Name <b className='text-red-500'>*</b></label>
              <input
                    id="full_name"
                    name='fullName'
                    placeholder={"Enter your full ame"}
                    value={formData?.fullName}
                    onChange={handleInputChange}
                    required
                    className="rounded-md  py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-bold outline-none focus:border-[#012206] focus:shadow-md"
                />
                {errorMessages?.name && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.name}</span>}
            </div>

            <div className=" mb-2">
              <label htmlFor="email" className='font-bold mb-0 text-[#41bd18]'>Email <b className='text-red-500'>*</b></label>
              <input
                    id="email"
                    name='email'
                    placeholder={"Enter your email"}
                    value={formData?.email}
                    type='email'
                    onChange={handleInputChange}
                    required
                    className="rounded-md  py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-bold outline-none focus:border-[#012206] focus:shadow-md"
                />
                {errorMessages?.email && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.email}</span>}
            </div>

            <div className="mb-0">
              <label htmlFor="contact" className='font-bold mb-3 text-[#41bd18]'>Contact <b className='text-red-500'>*</b></label>
                <input
                    id="contact"
                    name='contact'
                    placeholder={"Enter your contact"}
                    value={formData?.contact}
                    type='tel'
                    onChange={handleInputChange}
                    required
                    className="rounded-md  py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-bold outline-none focus:border-[#012206] focus:shadow-md"
                />
               {errorMessages?.contact && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.contact}</span>}
              </div>

              <label htmlFor="cat" className=" mt-2 block font-bold text-base font-medium text-[#41bd18]">
                        Select LGA <b className='text-red-500'>*</b>
              </label>
              <div className="mt-2 flex justify-content-center w-full  mb-2">
                  <Dropdown filter={true} placeholder='Select LGA' name='lga'  value={formData?.lga} onChange={handleInputChange} options={lgas} optionLabel="lga" className="w-full text-green-500 border border-green-500 px-4 py-2" />
                  {errorMessages?.lga && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.lga}</span>}
              </div>
              
              <label htmlFor="cat" className="block font-bold text-base font-medium text-[#41bd18]">
                      Select Ward  <b className='text-red-500'>*</b>
              </label>
      
              <div className="card flex justify-content-center w-full mb-3">
                      <Dropdown filter={true} placeholder='Select Ward' name='ward'  value={formData?.ward} onChange={handleInputChange} options={wardsData} optionLabel="ward" className="w-full text-green-500 border border-green-500 px-4 py-2" />
                      {errorMessages?.ward && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.ward}</span>}

              </div>


            <div className="mb-0">
              <label htmlFor="password" className='font-bold mb-3 text-[#41bd18]'>Password <b className='text-red-500'>*</b></label>
                <input
                    id="password"
                    name='password'
                    placeholder={"Enter your password"}
                    value={formData?.password}
                    type='password'
                    onChange={handleInputChange}
                     pattern="[0-9a-fA-F]{4,8}"
                    required
                    className="rounded-md  py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-bold outline-none focus:border-[#012206] focus:shadow-md"
                />
                 {errorMessages?.password && <span className="text-sm flex flex-row gap-x-1 py-2 text-red-500 font-bold"><FaExclamationCircle/>{errorMessages?.password}</span>}

            </div>

            <div className="card flex flex-wrap justify-content-center gap-3 mt-4">
                        <button
                        onClick={handleSubmit}
                                type="submit"
                                disabled={processing}
                                className={`hover:shadow-form grid grid-cols-2 w-full items-center justify-content-center py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-green-300' : 'bg-green-500'}`}
                            >
                                {processing && (
                                    <div className="items-center">
                                        <ProgressSpinner style={{ width: '30px', height: '30px', color: 'red' }} strokeWidth="6" fill="none" animationDuration=".2s" />
                                    </div>
                                )}
                                <div className="items-center justify-center text-white">
                                    Register
                                </div>
                        </button>
                </div>

          </div>
        </form>
      </div>
    </div>
   </div>
  );
};

export default RegisterPage;
